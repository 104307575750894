import { LoginRounded } from "@mui/icons-material";
import { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import GameFrame from "./components/GameFrame";
import LoginDialog from "./components/LoginDialog";
import { useWindowSize } from "./hooks/useWindowSize";
import LoadingDialog from "./utils/LoadingDialog";
import { screenSizeData } from "./utils/ScreenManager";
import { useGetGameDetailsQuery } from "./services/game";
import { DATA_KEY, GAME_ID } from "./utils/ImiData";
import { Backdrop, CircularProgress } from "@mui/material";
import FeedbackDialog from "./utils/FeedbackDialog";
import { useDispatch, useSelector } from "react-redux";
import { homeActions } from "./store/store";
import cognito from "./services/cognito";
import {
  useGetUserTokenMutation
} from "./services/user";

function App() {
  //#region HOOKS
  [screenSizeData.currentScreenWidth, screenSizeData.currentScreenHeight] =
    useWindowSize();

  const dispatch = useDispatch();

  const inGameLogBoolRef = useRef(false);

  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  const userToken = useSelector((state) => state.home.userToken);

  useEffect(() => {
    const tokens =  cognito.getLocalTokens();
    if(tokens != null && tokens.access_token_2 != null){
      dispatch(homeActions.setUserToken(tokens.access_token_2));
    }
  }, [])

  useEffect(() => {
    const eventListener = window.addEventListener(
      "message",
      (event) => {
        if (typeof event.data === "string") {
          //CHECK FOR JSON OBJECT
          try {
            console.log(event.data);
            let commandObj = JSON.parse(event.data);

            if (commandObj.command === "HIDE_NAV") {
              
              const tokens =  cognito.getLocalTokens();
              
              if(tokens.accessToken != null){
                dispatch(homeActions.setUserToken(tokens.accessToken));
              }
            } else if (commandObj.command === "EXIT") {
              window.open("https://app.imigames.io");
            } else if (commandObj.command === "SUBSCRIBE") {
            } else if (commandObj.command === "LOGOUT") {
              inGameLogBoolRef.current = false;
              localStorage.removeItem(DATA_KEY);
              dispatch(homeActions.setUserToken(null));
            } else if (commandObj.command === "LOGIN") {
              // setOpenLoginDialog(true);
              const tokens =  cognito.getLocalTokens();
              if(tokens == null){
                cognito.authenticate("login");
              }else{
                console.log("Aff");
                 localStorage.setItem("access_token", tokens.accessToken);
                // window.history.replaceState({}, document.title, window.location.origin);
                // dispatch(homeActions.setUserToken(tokens.accessToken));
                getUserToken({IDtoken : tokens.idToken,accessToken : tokens.accessToken});
              }
            }
          } catch (e) {
            //console.log("JSON Parse Error");
          }
        }
      },
      false
    );

    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, []);

  // useEffect(() => {
  //   //RETRIEVE DATA ON PAGE REFRESH
  //   if (userToken === null) {
  //     let _token = localStorage.getItem(DATA_KEY);

  //     if (_token) {
  //       dispatch(homeActions.setUserToken(_token));
  //     }
  //   }
  // }, []);

  useEffect(() => {
    //RETRIEVE DATA ON PAGE REFRESH
    if(cognito.checkURLTokens()){
      let _token =  cognito.getLocalTokens();
      // console.log(_token.accessToken,_token.idToken);
      //  console.log("Eff");
       localStorage.setItem("access_token", _token.accessToken);
      // window.history.replaceState({}, document.title, window.location.origin);
      // dispatch(homeActions.setUserToken(_token.accessToken));
      getUserToken({IDtoken : _token.idToken,accessToken : _token.accessToken});
    }

  }, []);
  //#endregion

  //#region Get Access Token API

  let getAccessTokenContent;

  const [
    getUserToken,
    {
      isLoading: loadingUserTokenAPI,
      isSuccess: userTokenAPISuccess,
      data: userTokenAPIData,
      error: userTokenAPIError,
    },
  ] = useGetUserTokenMutation();

  if (loadingUserTokenAPI) {

    getAccessTokenContent = (
      <Backdrop
        open={true}
        sx={{
          backgroundColor: "black",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress sx={{ color: "blue" }} />
      </Backdrop>
    );

  }else if(userTokenAPISuccess){
   // localStorage.setItem("access_token_2", userTokenAPIData.access_token);
   // window.location.href = window.location.origin;
   window.history.replaceState({}, document.title, window.location.origin);
   // dispatch(homeActions.setUserToken(userTokenAPIData.access_token));
   dispatch(homeActions.setUserToken(localStorage.getItem("access_token")));

  }else if(userTokenAPIError){

    getAccessTokenContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          window.location.reload();
        }}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong",
        }}
      />
    );
  } else {
    getAccessTokenContent = null;
  }

  //#endregion

  //#region LOGIN DIALOG

  // const onLogInDialogActionBtnClick = (logInBool = false) => {
  //   if (logInBool) {
  //     inGameLogBoolRef.current = true;
  //   } else {
  //     document.getElementById("gameIframe").src += "";
  //   }

  //   setOpenLoginDialog(false);
  // };

  // let loginDialogContent;

  // if (openLoginDialog) {
  //   loginDialogContent = (
  //     <LoginDialog
  //       onClose={onLogInDialogActionBtnClick}
  //       open={true}
  //     ></LoginDialog>
  //   );
  // } else {
  //   loginDialogContent = null;
  // }
  //#endregion

  //#region GET GAME URL
  let getGameDetailsAPIContent;

  getGameDetailsAPIContent = (
    <GameFrame
      // gameUrl={getGameDetailsAPIData?.url}
      gameUrl={"game/index.html"}
      inGameLoginBool={inGameLogBoolRef.current}
      token={userToken}
    ></GameFrame>
  );

  // const {
  //   isLoading: isLoadingGetGameDetailsAPI,
  //   isSuccess: isSuccessGameGameDetailsAPI,
  //   data: getGameDetailsAPIData,
  //   error: getGameDetailsAPIError,
  // } = useGetGameDetailsQuery({ uuid: GAME_ID });

  // if (isLoadingGetGameDetailsAPI) {
  //   getGameDetailsAPIContent = (
  //     <Backdrop
  //       open={true}
  //       sx={{
  //         backgroundColor: "black",
  //         zIndex: (theme) => theme.zIndex.drawer + 1,
  //       }}
  //     >
  //       <CircularProgress sx={{ color: "blue" }} />
  //     </Backdrop>
  //   );
  // } else if (isSuccessGameGameDetailsAPI) {
  //   //console.log(getGameDetailsAPIData);
  //   getGameDetailsAPIContent = (
  //     <GameFrame
  //       // gameUrl={getGameDetailsAPIData?.url}
  //       gameUrl={"game/index.html"}
  //       inGameLoginBool={inGameLogBoolRef.current}
  //       token={userToken}
  //     ></GameFrame>
  //   );
  // } else if (getGameDetailsAPIError) {
  //   // getGameDetailsAPIContent = (
  //   //   <FeedbackDialog
  //   //     open={true}
  //   //     onClose={() => {
  //   //       window.location.reload();
  //   //     }}
  //   //     data={{
  //   //       title: "Oops!",
  //   //       msg: "Something Went Wrong",
  //   //     }}
  //   //   />
  //   // );
  //   getGameDetailsAPIContent = (
  //     <GameFrame
  //       // gameUrl={getGameDetailsAPIData?.url}
  //       gameUrl={"game/index.html"}
  //       inGameLoginBool={inGameLogBoolRef.current}
  //       token={userToken}
  //     ></GameFrame>
  //   );
  // } else {
  //   getGameDetailsAPIContent = null;
  // }
  //#endregion

  return (
    <Fragment>
      {getGameDetailsAPIContent}
      {getAccessTokenContent}
    </Fragment>
  );
}

export default App;
