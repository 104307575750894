// export const baseUrl = "https://api.staging.imigames.io:443/api/v1";/user/v1/id-token/{token}
// export const baseUrl = "https://api.imigames.io:443/api/v1";
// export const baseUrl = "https://auth.747.fun";
//export const baseUrl = "https://535p48girc.execute-api.ap-southeast-1.amazonaws.com";
export const baseUrl = "https://d2gi74td4gf3sj.cloudfront.net";

export const googleWebClientID =
  "356024293196-ma8od8hv9nuhrqiu3n7ms8cb4is7f8ck.apps.googleusercontent.com";

export const legalContracts = {
  termsCondUrl:
    "https://imigames.s3.ap-southeast-1.amazonaws.com/html_info/imi_app/terms_and_conditions/index.html",
  privacyPolicyUrl:
    "https://imigames.s3.ap-southeast-1.amazonaws.com/html_info/imi_app/privacy_policy/index.html",
};

export const DATA_KEY = "SUDOKU_USER_DATA";
export const GAME_ID = "6a60d9a6-1a3a-4f0f-bb4e-57538d5146b6";
